import "./App.css";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Theme from "components/theme";
import Enrollment from "components/enrollment";
import Confirmation from "components/confirmation";
import Terms from "components/terms";
import Privacy from "components/privacy";
import Hero from "components/hero";
import Register from "components/register";

function App() {
  return (
    <Theme>
      <CssBaseline>
        <BrowserRouter>
          <Routes>
            <Route path="/enroll/:sponsor" element={<Enrollment />} />
            <Route path="/enroll/:sponsor/:subject" element={<Register />} />
            <Route
              path="/enroll/:sponsor/:subject/:code"
              element={<Register />}
            />
            <Route
              exact
              path="/confirmation/:sponsor/:subject"
              element={<Confirmation />}
            />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route path="/:sponsor" element={<Hero />} />
            <Route path="/" element={<Hero />} />
            <Route path="*" element={<Hero />} />
          </Routes>
        </BrowserRouter>
      </CssBaseline>
    </Theme>
  );
}

export default App;
